import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles';
import { Grid, Typography, Chip } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

const styles = theme => ({
    root: {
        borderLeft: `solid 5px ${blue[300]}`,
        paddingLeft: '10px'
    }
})

class Address extends Component {
    state = {
        address_line_1: '',
        address_line_2: '',
        landmark: '',
        city: '',
        district: '',
        state: '',
        pin: ''
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.props.onChangeData(this.state)
        })
    }

    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>

                <Typography
                    variant="h6"
                    gutterBottom
                >
                    {this.props.title}
                </Typography>

                <Grid container>

                    <Grid item xs={12} md={6} sm={12} lg={6} className="p-2">
                        <Typography
                            variant="subtitle2"
                        >
                            Address Line 1 <span className="red-alert">*</span>
                        </Typography>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={`Address Line 1`}
                            onChange={this.onChange}
                            name={`address_line_1`}
                            required
                            value={this.state.address_line_1}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} lg={6} className="p-2">
                        <Typography
                            variant="subtitle2"
                        >
                            Address Line 2
                        </Typography>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={`Address Line 2`}
                            onChange={this.onChange}
                            name={`address_line_2`}
                            value={this.state.address_line_2}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} sm={12} lg={12} className="p-2">
                        <Typography
                            variant="subtitle2"
                        >
                            Landmark
                        </Typography>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={`Landmark`}
                            onChange={this.onChange}
                            name={`landmark`}
                            value={this.state.landmark}
                        />
                    </Grid>

                    <Grid item xs={12} md={3} sm={12} lg={3} className="p-2">
                        <Typography
                            variant="subtitle2"
                        >
                            City / Town <span className="red-alert">*</span>
                        </Typography>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={`City/Town`}
                            onChange={this.onChange}
                            name={`city`}
                            required
                            value={this.state.city}
                        />
                    </Grid>

                    <Grid item xs={12} md={3} sm={12} lg={3} className="p-2">
                        <Typography
                            variant="subtitle2"
                        >
                            District <span className="red-alert">*</span>
                        </Typography>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={`District`}
                            onChange={this.onChange}
                            name={`district`}
                            required
                            value={this.state.district}
                        />
                    </Grid>

                    <Grid item xs={12} md={3} sm={12} lg={3} className="p-2">
                        <Typography
                            variant="subtitle2"
                        >
                            State <span className="red-alert">*</span>
                        </Typography>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={`State`}
                            onChange={this.onChange}
                            name={`state`}
                            required
                            value={this.state.state}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12} lg={3} className="p-2">
                        <Typography
                            variant="subtitle2"
                        >
                            PIN <span className="red-alert">*</span>
                        </Typography>
                        <input
                            type="number"
                            minLength="6"
                            maxLength="6"
                            className="form-control"
                            placeholder={`PIN`}
                            onChange={this.onChange}
                            name={`pin`}
                            required
                            value={this.state.pin}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} sm={12} lg={6} className="p-2">
                        <Typography
                            variant="subtitle2"
                        >
                            Landline Number 
                        </Typography>
                        <input
                            type="number"
                            minLength="6"
                            maxLength="6"
                            className="form-control"
                            placeholder={`Landline Number`}
                            onChange={this.onChange}
                            name={`landline_number`}
                            value={this.state.landline_number}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} sm={12} lg={6} className="p-2">
                        <Typography
                            variant="subtitle2"
                        >
                            Contact Number (if any)
                        </Typography>
                        <input
                            type="number"
                            minLength="6"
                            maxLength="6"
                            className="form-control"
                            placeholder={`Contact Number`}
                            onChange={this.onChange}
                            name={`contact_number`}
                            value={this.state.contact_number}
                        />
                    </Grid>

                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(Address)