import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, Typography, Grid, Badge, Chip, CardActions, Button } from '@material-ui/core';
import Address from './Address';
import LastSchool from './LastSchool';
import FileUpload from './FileUpload';
import LanguagesKnown from './LanguagesKnown';
import FinalDialog from './FinalDialog';
import { GetData, PostData } from '../../api/service';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const styles = theme => ({

})

const fields = [
    {
        name: 'first_name',
        label: 'First Name',
        required: true,
        type: 'input',
        select: []
    },
    {
        name: 'middle_name',
        label: 'Middle Name',
        required: false,
        type: 'input',
        select: []
    },
    {
        name: 'last_name',
        label: 'Last Name',
        required: true,
        type: 'input',
        select: []
    },
    {
        name: 'email',
        label: 'Student Email ID (Please provide your own Email ID)',
        required: true,
        type: 'input',
        select: []
    },
    {
        name: 'phone',
        label: 'Student Phone Number (OTP Verification will be done)',
        required: true,
        type: 'input',
        select: []
    },
    {
        name: 'gender',
        label: 'Select Gender',
        required: true,
        type: 'select',
        select: [
            {
                label: 'MALE',
                value: 'MALE'
            },
            {
                label: 'FEMALE',
                value: 'FEMALE'
            },
            {
                label: 'OTHERS',
                value: 'OTHERS'
            }
        ]
    },
    {
        name: 'date_of_birth',
        label: 'Date of Birth',
        required: true,
        type: 'date',
        select: []
    },
    {
        name: 'father_name',
        label: "Father's Name",
        required: true,
        type: 'input',
        select: []
    },
    {
        name: 'father_phone',
        label: "Father's Phone",
        required: true,
        type: 'input',
        select: []
    },
    {
        name: 'f_occupation',
        label: "Father's Occupation",
        required: true,
        type: 'input',
        select: []
    },
    {
        name: 'mother_name',
        label: "Mother's Name",
        required: true,
        type: 'input',
        select: []
    },
    {
        name: 'mother_phone',
        label: "Mother's Phone",
        required: false,
        type: 'input',
        select: []
    },
    {
        name: 'm_occupation',
        label: "Mother's Occupation",
        required: true,
        type: 'input',
        select: []
    },

    {
        name: 'caste',
        label: 'Select Caste',
        required: true,
        type: 'select',
        select: [
            {
                label: 'GENERAL',
                value: 'GENERAL'
            },
            {
                label: 'ST(P)',
                value: 'ST(P)'
            },
            {
                label: 'ST(H)',
                value: 'ST(H)'
            },
            {
                label: 'OBC',
                value: 'OBC'
            },
            {
                label: 'OBC(NCL)',
                value: 'OBC(NCL)'
            },
            {
                label: 'SC',
                value: 'SC'
            },
            {
                label: 'MOBC',
                value: 'MOBC'
            }
        ]
    },
    {
        name: 'blood_group',
        label: "Blood Group",
        required: false,
        type: 'input',
        select: []
    },
    {
        name: 'quota',
        label: 'Admitted under quota',
        required: true,
        type: 'select',
        select: [
            {
                label: 'UR',
                value: 'UR'
            },
            {
                label: 'ST(P) Quota',
                value: 'STP'
            },
            {
                label: 'ST(H) Quota',
                value: 'STH'
            },
            {
                label: 'OBC Quota',
                value: 'OBC'
            },
            {
                label: 'OBC(NCL) Quota',
                value: 'OBC(NCL)'
            },
            {
                label: 'SC Quota',
                value: 'SC'
            },
            {
                label: 'CGE Quota',
                value: 'CGE'
            },
            {
                label: 'Ex-Tea Garden Quota',
                value: 'EXTGLC'
            },
            {
                label: 'Tea Garden Quota',
                value: 'TGLC'
            },
            {
                label: 'Freedom Fighter Quota',
                value: 'FF'
            },
            {
                label: 'NCC Quota',
                value: 'NCC'
            },
            {
                label: 'NSS Quota',
                value: 'NSS'
            },
            {
                label: 'SPORTS Quota',
                value: 'SPORTS'
            },
            {
                label: 'Differently Abled Quota',
                value: 'PH'
            },
            {
                label: 'Retired Defence Quota',
                value: 'RDP'
            },
            {
                label: 'Economically Weaker Section',
                value: 'EWS'
            },
        ]
    },
    {
        name: 'religion',
        label: "Religion",
        required: true,
        type: 'input',
        select: []
    },
    {
        name: 'nationality',
        label: "Nationality",
        required: true,
        type: 'input',
        select: []
    },
    {
        name: 'is_handicapped',
        label: 'Are you physically handicapped ?',
        required: true,
        type: 'select',
        select: [
            {
                label: 'YES',
                value: 'YES'
            },
            {
                label: 'NO',
                value: 'NO'
            }
        ]
    },
]

class FormOne extends Component {
    state = {
        course: '',
        course_type: '',
        APPLICATION_NO: '',
        programs: [],
        dept_code: '',
        is_lateral: '',
        branches: [],
        year_of_admission: '',
        date_of_admission: '',
        date_of_birth: '',
        semester: '',
        roll_no: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        phone: '',
        gender: '',
        father_name: '',
        father_phone: '',
        f_occupation: '',
        mother_name: '',
        mother_phone: '',
        m_occupation: '',
        blood_group: '',
        caste: '',
        is_handicapped: '',
        disability: '',
        quota: '',
        religion: '',
        nationality: '',
        permanent_address: [],
        local_guardians_address: [],
        languages_known: [],
        last_school: [],
        cee_roll_no: '',
        cee_marks_obtained: '',
        cee_year: '',
        dp: [],
        signature: [],
        phone_verification: 0,
        isSubmitting: false,
        dialog: false,
        isLoaded: false,
        errors: []

    }

    componentDidMount() {



        GetData(`/getcourse`)
            .then((resp) => {
                return resp;
            }).then((resp) => {
                GetData(`/getdepartment`)
                    .then((res) => {
                        this.setState({
                            programs: resp,
                            branches: res
                        }, () => {
                            // check for data in the history

                            let data = this.props.history.location.state;


                            if (data !== undefined) {
                                // populate the data
                                this.setState({
                                    ...data,
                                    isLoaded: true
                                })
                            } else {
                                this.setState({
                                    isLoaded: true
                                })
                            }
                        })
                    })
            })
    }


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeData = (name, value) => {

        this.setState({
            [name]: value
        })
    }

    handleValidation = () => {
        let { dp, signature, last_school, errors } = this.state;
        let isValid = true;

        /* if (Array.isArray(dp) && dp.length === 0) {
            isValid = false;
            errors['dp'] = 'Please upload a file. If file is already shown, click Upload button to confirm'
        } else {
            errors['dp'] = '';
        }

        if (Array.isArray(signature) && signature.length === 0) {
            isValid = false;
            errors['signature'] = 'Please upload a file. If file is already shown, click Upload button to confirm'
        } else {
            errors['signature'] = '';
        } */

        if (Array.isArray(last_school) && last_school.length === 0) {
            isValid = false;
            errors['last_school'] = 'Please upload a file. If file is already shown, click Upload button to confirm'
        } else {
            errors['last_school'] = '';
        }



        this.setState({
            errors
        })

        return isValid;

    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.handleValidation()) {
            this.setState({
                isSubmitting: false,
                dialog: true
            })
        }

    }

    finalSubmit = (status) => {
        //console.log(status);
        this.setState({
            dialog: false,
            phone_verification: status,
            isSubmitting: true
        }, () => {
            // now we will post the student data to the server

            let d = this.state;

            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to do this.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => this.confirmFinalSubmit(d)
                    },
                    {
                        label: 'No',
                        onClick: () => this.cancelSubmission()
                    }
                ]
            });


        })
    }

    confirmFinalSubmit = (d) => {
        PostData(`/addstudentregistration`, d)
            .then((resp) => {
                if (resp.status === true) {

                    confirmAlert({
                        title: 'Successfully submitted',
                        message: resp.message,
                        buttons: [
                            {
                                label: 'Print Acknowledgement Form',
                                onClick: () => this.gotToAck(resp.student_id)
                            },
                            {
                                label: 'No'
                            }
                        ]
                    });

                } else {
                    confirmAlert({
                        message: resp.message,
                        buttons: [
                            {
                                label: 'Cancel',
                                onClick: () => this.cancelSubmission()
                            },
                            {
                                label: 'Re-submit',
                                onClick: () => this.confirmFinalSubmit(d)
                            }
                        ]
                    });
                }
            })
    }

    cancelSubmission = () => {
        this.setState({
            isSubmitting: false
        })
    }

    gotToAck = (student_id) => {
        this.props.history.push('/acknoledgement', student_id)
    }


    render() {
        let { classes } = this.props;

        let year = new Date().getFullYear();
        let yoa = [];
        for (let i = 0; i < 9; i++) {
            yoa.push(parseFloat(year) - parseFloat(i))
        }

        let pr = [];
        let br = [];

        if (this.state.isLoaded) {
            let programs = this.state.programs;
            if (Array.isArray(programs) && programs.length > 0) {
                pr = programs.map((el, index) =>
                    <option key={index} value={el.course_code}>{el.course_name}</option>
                )
            }


            let branches = this.state.branches;
            if (Array.isArray(branches) && branches.length > 0) {
                br = branches.map((el, index) =>
                    <option key={index} value={el.dept_code}>{el.dept_name}</option>
                )
            }
        }


        return (
            <div>


                <div className="alert alert-success">
                    <Typography
                        variant="caption"
                    >
                        Please insert correct information, otherwise your registration will be cancelled.
                    </Typography>
                    <br />
                    <Typography
                        variant="caption"
                    >
                        A confirmation message will be send to the mobile number registered. Please keep the phone in hand for OTP.
                    </Typography>
                    <br />
                    <Typography
                        variant="caption"
                    >
                        * marks fields are required
                    </Typography>
                </div>


                {!this.state.isLoaded ? <div className="alert alert-danger">
                    <Typography>
                        Loading data from the server . . . . Please wait
                    </Typography>
                </div> : null}

                <form onSubmit={this.onSubmit}>

                    {/* Academic Information */}

                    <Card raised>
                        <CardHeader
                            title={`Academic Information`}
                        />

                        <CardContent>

                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={12} md={3} lg={3}>

                                    <Typography
                                        variant="subtitle2"
                                    >
                                        Select University (ASTU) <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        placeholder="Select Program"
                                        onChange={this.onChange}
                                        name="course_type"
                                        required
                                        value={this.state.course_type}>
                                        <option value="">Select University</option>

                                        {/* <option value="1">GU - Modified Course</option> */}
                                        <option value="2">ASTU</option>

                                    </select>

                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>

                                    <Typography
                                        variant="subtitle2"
                                    >
                                        Select Program <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        placeholder="Select Program"
                                        onChange={this.onChange}
                                        name="course"
                                        required
                                        value={this.state.course}>
                                        <option value="">Select program</option>

                                        {pr}

                                    </select>

                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>

                                    <Typography
                                        variant="subtitle2"
                                    >
                                        Select Branch <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        placeholder="Select Branch"
                                        onChange={this.onChange}
                                        name="dept_code"
                                        required
                                        value={this.state.dept_code}>
                                        <option value="">Select Branch</option>

                                        {br}

                                    </select>

                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>

                                    <Typography
                                        variant="subtitle2"
                                    >
                                        Year of Admission <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        placeholder="Select Year"
                                        onChange={this.onChange}
                                        name="year_of_admission"
                                        required
                                        value={this.state.year_of_admission}>
                                        <option value="">Select year</option>

                                        {yoa.map((el, index) =>
                                            <option key={index} value={el}>{el}</option>
                                        )}

                                    </select>

                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>

                                    <Typography
                                        variant="subtitle2"
                                    >
                                        Date of Admission <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder={`Date of Admission`}
                                        onChange={this.onChange}
                                        name={`date_of_admission`}
                                        required
                                        value={this.state.date_of_admission}
                                    />

                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>

                                    <Typography
                                        variant="subtitle2"
                                    >
                                        Semester <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        placeholder="Select Semester"
                                        onChange={this.onChange}
                                        name="semester"
                                        required
                                        value={this.state.semester}>
                                        <option value="">Select Semester</option>
                                        {[1, 2, 3, 4, 5, 6, 7, 8].map((el, index) =>
                                            <option key={index} value={el}>{el}</option>
                                        )}
                                    </select>

                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>

                                    <Typography
                                        variant="subtitle2"
                                    >
                                        College Roll No <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`Roll No e.g 13-373`}
                                        onChange={this.onChange}
                                        name={`roll_no`}
                                        required
                                        value={this.state.roll_no}
                                    />

                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>

                                    <Typography
                                        variant="subtitle2"
                                    >
                                        Are you lateral ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        placeholder="Select"
                                        onChange={this.onChange}
                                        name="is_lateral"
                                        required
                                        value={this.state.is_lateral}>
                                        <option value="">Are you lateral ?</option>
                                        <option value={0}>No</option>
                                        <option value={1}>Yes</option>

                                    </select>

                                </Grid>

                            </Grid>

                        </CardContent>

                    </Card>

                    <br />


                    <Card raised>
                        <CardHeader
                            title={`Basic Information`}
                        />
                        <CardContent>
                            <Grid container>
                                {fields.map((el, index) =>

                                    <Grid key={index} item xs={12} sm={12} md={4} lg={4} className="p-2">
                                        <Typography
                                            variant="subtitle2"
                                        >
                                            {el.label} {el.required === true ? <span className="red-alert">*</span> : null}
                                        </Typography>
                                        {el.type === 'input' ? <input

                                            className="form-control"
                                            placeholder={el.label}
                                            onChange={this.onChange}
                                            name={el.name}
                                            required={el.required}
                                            value={this.state[el.name]}
                                        /> : null}

                                        {el.type === 'select' ? (
                                            <select
                                                className="form-control"
                                                placeholder={el.label}
                                                onChange={this.onChange}
                                                name={el.name}
                                                required={el.required}
                                                value={this.state[el.name]}>
                                                <option value="">{el.label}</option>
                                                {el.select.map((el1, index1) =>
                                                    <option key={index1} value={el1.value}>{el1.label}</option>
                                                )}
                                            </select>
                                        ) : null}

                                        {el.type === 'date' ? <input
                                            className="form-control"
                                            type="date"
                                            placeholder={el.label}
                                            onChange={this.onChange}
                                            name={el.name}
                                            required={el.required}
                                            value={this.state[el.name]}
                                        /> : null}


                                    </Grid>

                                )}

                                {this.state.is_handicapped === 'YES' ? (<Grid item xs={12} sm={12} md={4} lg={4} className="p-2">
                                    <Typography
                                        variant="subtitle2"
                                    >
                                        If Yes, Describe your diability <span className="red-alert">*</span>
                                    </Typography>
                                    <textarea
                                        className="form-control"
                                        placeholder={`Describe your disability`}
                                        onChange={this.onChange}
                                        name={`disability`}
                                        required
                                        value={this.state.disability}
                                    ></textarea>

                                </Grid>) : null}



                            </Grid>


                        </CardContent>
                    </Card>

                    <br />

                    <Card raised>
                        <CardHeader
                            title={`Contact Information`}
                        />

                        <CardContent>


                            <Address
                                title={`Permanent Address`}
                                onChangeData={this.onChangeData.bind(this, 'permanent_address')}
                                value={this.state.permanent_address}
                            />

                            <br />

                            <Address
                                title={`Local Guardians' Address`}
                                onChangeData={this.onChangeData.bind(this, 'local_guardians_address')}
                                value={this.state.local_guardians_address}
                            />
                        </CardContent>

                    </Card>

                    <br />

                    <Card raised>
                        <CardHeader
                            title={`Educational Information`}
                        />

                        <CardContent>

                            <LanguagesKnown
                                onChangeData={this.onChangeData.bind(this, 'languages_known')}
                            />

                            <br />
                            <br />


                            {this.state.errors['last_school'] !== '' ? (
                                <Typography
                                    variant="caption"
                                    style={{ color: 'red' }}
                                >
                                    {this.state.errors['last_school']}
                                </Typography>
                            ) : null}
                            <LastSchool
                                onChangeData={this.onChangeData.bind(this, 'last_school')}
                            />

                            <br />
                            <br />

                            <Typography
                                variant="h6"
                                gutterBottom
                            >
                                CEE/JLEE/JMEE Examination Details
                        </Typography>

                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={12} md={3} lg={3}>

                                    <Typography
                                        variant="subtitle2"
                                    >
                                        CEE/JLEE/JMEE Roll Number
                                    </Typography>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`CEE/JLEE/JMEE Roll No`}
                                        onChange={this.onChange}
                                        name={`cee_roll_no`}

                                        value={this.state.cee_roll_no}
                                    />

                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>

                                    <Typography
                                        variant="subtitle2"
                                    >
                                        Marks Obtained in CEE/JLEE/JMEE
                                    </Typography>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`Marks Obtained in CEE/JLEE/JMEE`}
                                        onChange={this.onChange}
                                        name={`cee_marks_obtained`}

                                        value={this.state.cee_marks_obtained}
                                    />

                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>

                                    <Typography
                                        variant="subtitle2"
                                    >
                                        CEE/JLEE/JMEE Year
                                    </Typography>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`CEE/JLEE/JMEE year`}
                                        onChange={this.onChange}
                                        name={`cee_year`}
                                        value={this.state.cee_year}
                                    />

                                </Grid>



                            </Grid>
                        </CardContent>
                    </Card>

                    <br />

                    <Card raised>
                        <CardHeader
                            title={`File Upload`}
                        />

                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    {this.state.errors['dp'] !== '' ? (
                                        <Typography
                                            variant="caption"
                                            style={{ color: 'red' }}
                                        >
                                            {this.state.errors['dp']}
                                        </Typography>
                                    ) : null}
                                    {this.state.isLoaded ? <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Upload Your Photo (size below 500kb)
                                            </Typography>
                                        }
                                        type={`ADMISSION_PHOTO`}
                                        onChange={this.onChangeData.bind(this, 'dp')}
                                        data={this.state.dp}
                                        cee_roll_no={this.state.APPLICATION_NO}
                                        file_type="P"
                                    /> : null}
                                    <br />

                                    <div className="alert alert-success">
                                        <ul>
                                            <li>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    If the above mentioned photograph is correct then press upload button to confirm
                                            </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    Photograph must be a recent passport style colour picture.
                                            </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    Dimensions 200 x 230 pixels (preferred)
                                            </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    Size of file should be between 5kb-400kb
                                            </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    After selecting the file, press the Upload button to upload
                                            </Typography>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    {this.state.errors['signature'] !== '' ? (
                                        <Typography
                                            variant="caption"
                                            style={{ color: 'red' }}
                                        >
                                            {this.state.errors['signature']}
                                        </Typography>
                                    ) : null}
                                    {this.state.isLoaded ? <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Upload Your Signature (size below 100kb)
                                            </Typography>
                                        }
                                        type={`ADMISSION_SIGNATURE`}
                                        onChange={this.onChangeData.bind(this, 'signature')}
                                        data={this.state.signature}
                                        cee_roll_no={this.state.APPLICATION_NO}
                                        file_type="S"
                                    /> : null}

                                    <br />

                                    <div className="alert alert-success">
                                        <ul>
                                            <li>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    If the above mentioned photograph is correct then press upload button to confirm
                                            </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    The applicant has to sign on white paper with Black Ink pen.
                                            </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    The signature must be signed only by the applicant and not by any other person.
                                            </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    Dimensions 140 x 60 pixels (preferred)
                                            </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    Size of file should be between 2kb - 100kb
                                            </Typography>
                                            </li>
                                            <li>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    After selecting the file, press the Upload button to upload
                                            </Typography>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>

                    </Card>

                    <br />

                    <Card raised>
                        <CardHeader
                            title={`Declaration`}
                        />

                        <CardContent>
                            <Typography>
                                By Clicking the "Final Submit" button, I hereby declare that the details furnished above are true and correct to the best of my knowledge
    and belief and I undertake to inform you of any changes therein, immediately.
                                </Typography>
                        </CardContent>

                        <CardActions style={{ justifyContent: 'flex-end' }}>
                            {!this.state.isSubmitting ? (<Button
                                type="submit"
                                size="large"
                                color="primary"
                                variant="contained"
                            >
                                Final Submit
                        </Button>) : null}
                        </CardActions>

                    </Card>

                </form>


                {/* Dialog box for OTP / Skip */}


                {this.state.dialog ? (
                    <FinalDialog
                        open={this.state.dialog}
                        phone={this.state.phone}
                        finalSubmit={this.finalSubmit.bind(this)}

                    />
                ) : null}


            </div>
        )
    }
}

export default withStyles(styles)(FormOne)