import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import HomePage from './Containers/HomePage';
import NotFound from './Containers/NotFound';
import SignUpLanding from './Components/SignUp/SignUpLanding';
import AckContainer from './Components/SignUp/AckContainer';




class App extends Component {
  render() {
    return (
      <Router history={history} >
        <div>
          <Switch>
            <Route exact path="/" render={(props) => <HomePage {...props} />} />
            <Route exact path="/reprint" render={(props) => <HomePage {...props} />} />
            <Route exact path="/signup" render={(props) => <SignUpLanding {...props} />} />
            <Route exact path="/acknoledgement" render={(props) => <AckContainer {...props} />} />

            <Route component={NotFound} />

          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
