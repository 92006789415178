import React, { Component } from 'react'
import { withStyles, Typography, Container, Button, Divider } from '@material-ui/core';
import { PostData, GetData } from '../../api/service';
import ReactToPrint from 'react-to-print';
import Barcode from 'react-barcode';

const styles = theme => ({
    lightRed: {
        backgroundColor: '#2f2f2f'
    }
})

class AckContainer extends Component {
    state = {
        student_id: '',
        isLoaded: false
    }

    componentDidMount() {
        let student_id = this.props.history.location.state;
        if (student_id !== undefined) {
            let d = {
                student_id: student_id
            }
            PostData(`/getstudentack`, d)
                .then((resp) => {
                    if (resp === false) {
                        this.props.history.replace('/')
                    } else {
                        this.setState({
                            ...resp,
                            isLoaded: true
                        })
                    }
                })
        }
    }

    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <Container style={{ padding: '10px' }}>
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        Print Acknoledgement Receipt
                    </Typography>

                    {this.state.isLoaded ? (<div>

                        <ReactToPrint
                            trigger={() =>
                                <Button
                                    variant="outlined"
                                    color="primary"

                                >
                                    Print
                        </Button>
                            }
                            ref={el => this.buttonRef = el}
                            content={() => this.componentRef}
                            pageStyle={{ margin: '10px' }}
                            copyStyles={true}
                        />

                        <Button
                            onClick={() => this.props.history.replace('/')}
                        >
                            GO BACK
                        </Button>

                    </div>) : null}
                    <br />
                    <br />

                    <Divider />


                    <br />
                    <br />


                    {this.state.isLoaded ? <AckForm
                        ref={el => (this.componentRef = el)}
                        {...this.state}
                    /> : null}



                </Container>

            </div>
        )
    }
}


export default withStyles(styles)(AckContainer);


class AckForm extends Component {
    render() {

        return (
            <div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={require('./../../assets/logo.png')}
                                    style={{ width: '50%' }}
                                />
                            </td>


                            <td width="60%" align="center">
                                <Typography
                                    variant="h6"
                                >
                                    অসম অভিযান্ত্ৰিক মহাবিদ্যালয়
                                </Typography>
                                <Typography
                                    variant="h5"
                                >
                                    Assam Engineering College
                                </Typography>

                                <Typography
                                    variant="body2"
                                >
                                    Jalukbari, Guwahati - 781013
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    Acknowledgement
                                </Typography>
                            </td>

                            <td width="20%" align="center">
                                <ImageHolder
                                    data={this.props.dp}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>


                <br />

                <Typography
                    variant="h6"
                >
                    Academic Details
                </Typography>
                <table width="100%" className="table table-bordered">
                    <tbody>
                        <tr>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    Class: {this.props.course}
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    Branch : {this.props.dept_name}
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    Semester : {this.props.semester}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    Roll No: {this.props.roll_no}
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    Year of Admission : {this.props.year_of_admission}
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    Date of Admission : {this.props.date_of_admission}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />

                <Typography
                    variant="h6"
                >
                    Basic Details
                </Typography>

                <table width="100%" className="table table-bordered">
                    <tbody>
                        <tr>
                            <td colSpan="2" className="bg-light">
                                <Typography
                                    variant="subtitle2"

                                >
                                    Student ID
                                </Typography>
                            </td>
                            <td colSpan="2">


                                <Barcode
                                    value={this.props.student_id}
                                    height="50"
                                    displayValue={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="bg-light">
                                <Typography
                                    variant="subtitle2"

                                >
                                    Name of the student
                                </Typography>
                            </td>
                            <td colSpan="2">
                                <Typography
                                    variant="subtitle2"
                                >
                                    {this.props.first_name} {this.props.middle_name} {this.props.last_name}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Email
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {this.props.email}
                                </Typography>
                            </td>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Phone
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {this.props.phone}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Gender
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {this.props.gender}
                                </Typography>
                            </td>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Caste
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {this.props.caste}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Father's Name
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {this.props.father_name} {this.props.father_phone !== '' ? `/ ` + this.props.father_phone : null}
                                </Typography>
                            </td>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Mother's Name
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {this.props.mother_name} {this.props.mother_phone !== '' ? `/ ` + this.props.mother_phone : null}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Father's Occupation
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {this.props.f_occupation}
                                </Typography>
                            </td>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Mother's Occupation
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {this.props.m_occupation}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Blood Group
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {this.props.blood_group}
                                </Typography>
                            </td>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Nationality
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {this.props.nationality}
                                </Typography>
                            </td>
                        </tr>

                        <tr>

                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Nationality
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {this.props.nationality}
                                </Typography>
                            </td>

                            <td className="bg-light">
                                &nbsp;
                            </td>
                            <td>
                                &nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />

                <Typography
                    variant="h6"
                >
                    Educational Details
                </Typography>

                <table width="100%" className="table table-bordered">
                    <tbody>
                        <tr>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"

                                >
                                    Examination
                                </Typography>
                            </td>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"

                                >
                                    Board / University
                                </Typography>
                            </td>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"

                                >
                                    School / College / University
                                </Typography>
                            </td>

                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"

                                >
                                    Roll No
                                </Typography>
                            </td>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"

                                >
                                    Year of Passing
                                </Typography>
                            </td>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"

                                >
                                    Percentage
                                </Typography>
                            </td>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"

                                >
                                    Division
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                    {Array.isArray(this.props.last_school) && this.props.last_school.length > 0 ? (
                        <tbody>
                            {this.props.last_school.map((el, index) =>
                                <tr key={index}>
                                    <td>
                                        <Typography
                                            variant="subtitle2"
                                        >
                                            {el.examination}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            variant="subtitle2"
                                        >
                                            {el.board}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            variant="subtitle2"
                                        >
                                            {el.school}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            variant="subtitle2"
                                        >
                                            {el.roll_no}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            variant="subtitle2"
                                        >
                                            {el.year_of_passing}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            variant="subtitle2"
                                        >
                                            {el.percentage}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            variant="subtitle2"
                                        >
                                            {el.division}
                                        </Typography>
                                    </td>
                                </tr>

                            )}
                        </tbody>
                    ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="7" align="center">
                                        <Typography
                                            variant="caption"
                                        >
                                            No data available
                                    </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                </table>

                <br />

                <Typography
                    variant="h6"
                >
                    Contact Details
                </Typography>
                <table width="100%" className="table table-bordered">
                    <tbody>
                        <tr>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"

                                >
                                    Permanent Address
                                </Typography>
                            </td>
                            <td className="bg-light">
                                <Typography
                                    variant="subtitle2"

                                >
                                    Local Guardian's Address
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <ViewAddress
                                    data={this.props.permanent_address}
                                />
                            </td>
                            <td>
                                <ViewAddress
                                    data={this.props.local_guardians_address}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />

                <Typography
                    variant="subtitle1"
                >
                    The facts stated in the form are true. If found otherwise I shall have no objection to the steps taken by the college authority.
                    Further, I will abide by the rules and regulations of the college and promised to contribute to the growth and well being of the institution
                </Typography>

                <br />
                <br />
                <br />
                <Typography
                    variant="subtitle1"
                    align="right"
                >
                    Signature of the Student with date
                </Typography>
            </div>
        )
    }
}

export const ViewAddress = (props) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
                variant="subtitle2"
            >
                {props.data.address_line_1}
            </Typography>

            <Typography
                variant="subtitle2"
            >
                {props.data.address_line_2}
            </Typography>
            <Typography
                variant="subtitle2"
            >
                {props.data.landmark}
            </Typography>
            <Typography
                variant="subtitle2"
            >
                Dist:  {props.data.district}
            </Typography>

            <Typography
                variant="subtitle2"
            >
                State: {props.data.state}
            </Typography>
            <Typography
                variant="subtitle2"
            >
                City:  {props.data.city}
            </Typography>
            <Typography
                variant="subtitle2"
            >
                Pin:  {props.data.pin}
            </Typography>
        </div>
    )
}

class ImageHolder extends Component {
    state = {
        file_id: '',
        data: '',
        isLoaded: false
    }

    __getFile = (file_id) => {
        let d = {
            file_id: file_id
        }
        PostData(`/getfilesingle`, d)
            .then((resp) => {
                if (resp.hasOwnProperty('mime') && resp.hasOwnProperty('data')) {
                    let file = '';
                    if (resp.mime === 'image/jpeg' || resp.mime === 'image/png' || resp.mime === 'image/jpg') {
                        file = `data:${resp.mime};base64,${resp.data}`;
                    }
                    this.setState({
                        data: file,
                        isLoaded: true
                    })
                }
            })
    }

    componentDidMount() {
        let data = this.props.data;
        console.log(data)
        if (Array.isArray(data) && data.length > 0) {
            if (data[0].hasOwnProperty('file_id')) {
                this.__getFile(data[0].file_id)
            }
        }
    }

    render() {
        return (
            <div
                style={{ height: '45mm', width: '35mm' }}
            >
                {this.state.isLoaded ? <img
                    alt="sig-photo"
                    src={this.state.data}
                    style={{ width: '100%', height: '100%' }}

                /> : 'Loading . . . . '}
            </div>
        )
    }
}