import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { withStyles } from '@material-ui/styles';
import { Typography, Divider } from '@material-ui/core';
import FormOne from './FormOne';

const styles = theme => ({
    backBanner: {
        background: `url(${require('./../../assets/aec-banner.jpg')})`,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover'
    }
})

class SignUpLanding extends Component {

    state = {
        active: 0,
        isLoaded: false
    }




    render() {
        let { classes } = this.props;
        return (
            <div className={classes.backBanner}>


                <div className="container p-3">
                    <div className="row">
                        <div className="col-md-6">
                            <Typography
                                variant="h4"
                                gutterBottom
                                style={{ color: 'white' }}
                            >
                                Student Registration
                </Typography>
                        </div>
                        <div className="col-md-6 text-right">
                            <Link to="/" >
                                <img
                                    alt="aec-logo"
                                    src={require('./../../assets/logo.png')}
                                    style={{ width: '10%' }}
                                />
                            </Link>
                        </div>
                    </div>



                    <br />

                    <FormOne history={this.props.history} />

                </div>
            </div>
        )
    }
}

export default withStyles(styles)(SignUpLanding)