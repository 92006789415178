import React, { Component } from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textDiv: {
        display: 'flex',
        alignItems: 'center',
    }
})

class NotFound extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className="container">
                    <Grid container spacing={24}>
                        <Grid item md={6}>
                            <img
                                alt="minion-404"
                                src={require('./../assets/minon.png')}
                                style={{ width: '70%' }}
                            />
                        </Grid>
                        <Grid item md={6} className={classes.textDiv}>
                            <div style={{textAlign: 'center'}}>
                                <Typography
                                    variant="h1"
                                >
                                    404 !!!
                                </Typography>

                                <Typography
                                    variant="h5"
                                >
                                    Not Found.
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(NotFound)