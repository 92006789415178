import React, { Component } from 'react'
import { withStyles, Button, Typography, Chip, CircularProgress } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { PostFormData, DeleteData, GetData, PostData } from '../../api/service';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutline from '@material-ui/icons/HelpOutline'



const styles = theme => ({
    container: {
        backgroundColor: grey[100],

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    photoHolder: {
        border: ' solid thin lightgrey',
        width: 250,
        backgroundColor: 'white'
    },
    uploaderDiv: {
        padding: '10px',
        display: 'flex',
        backgroundColor: 'white',
        width: '100%',
        border: `solid thin ${grey[100]}`,
        alignItems: 'center',
        justifyContent: 'center'
    },
    uploadedFile: {
        width: '100%'
    }
})

const file_upload_limit = 500;

const toolText = 'Please upload .jpg / .png only. The maximum memory of the file should be below 500KB';

const allowed_ext = ['image/jpg', 'image/png', 'image/jpeg'];

class FileUpload extends Component {
    state = {
        type: this.props.type,
        isUploading: false,
        fileView: '',
        file: [],
        isUploaded: false,
        result: [],
        isValid: false,
        error: '',
        fromCee: false

    }

    uploadFile = () => {


        if (this.state.fileView !== '') {
            this.setState({
                isUploading: true
            })

            if (this.state.fromCee === false) {

                let formdata = new FormData();
                formdata.append('type', this.state.type);
                let index = 0;
                for (const f of this.state.file) {
                    formdata.append(`file[${index}]`, f);
                    index++;
                }

                PostFormData(`/addtempfile`, formdata)
                    .then((resp) => {

                        if (resp !== false) {
                            this.setState({
                                result: resp,
                                isUploaded: true
                            }, () => {
                                this.props.onChange(this.state.result)
                            })
                        }
                    })

            } else {
                // send base64 string to the server to upload

                let d = {
                    base_string: this.state.fileView,
                    type: this.state.type
                }

                PostData(`/addbasetofile`, d)
                    .then((resp) => {
                        if (resp !== false) {
                            this.setState({
                                result: resp,
                                isUploaded: true
                            }, () => {
                                this.props.onChange(this.state.result)
                            })
                        }
                    })
            }
        }
    }

    deleteFile = () => {
        let result = this.state.result;
        if (result.length > 0) {
            // perform the delete
            let file_id = result[0].file_id;

            let d = {
                file_id: file_id
            }
            PostData(`/deletetempfile`, d)
                .then((resp) => {
                    if (resp) {
                        this.setState({
                            result: [],
                            isUploading: false,
                            isUploaded: false,
                            file: [],
                            fileView: ''
                        }, () => {
                            this.props.onChange(this.state.result)
                        })
                    } else {
                        this.setState({
                            result: [],
                            isUploading: false,
                            isUploaded: false,
                            file: [],
                            fileView: ''
                        }, () => {
                            this.props.onChange(this.state.result)
                        })
                    }
                });
        } else {
            this.setState({
                result: [],
                isUploading: false,
                isUploaded: false,
                file: [],
                fileView: ''
            }, () => {
                this.props.onChange(this.state.result)
            })
        }
    }

    onChange = (e) => {

        let size = e.target.files[0].size;
        let size_in_kb = parseFloat(size) / 1024;

        if (size_in_kb <= file_upload_limit) {

            //check file type 

            let type = e.target.files[0].type;
            if (allowed_ext.includes(type)) {
                this.setState({
                    fileView: URL.createObjectURL(e.target.files[0]),
                    file: e.target.files,
                    error: ''
                })
            } else {
                this.setState({
                    error: 'Only .jpg and .png are allowed. Wrong file type!!',
                    file: [],
                    fileView: ''
                })
            }

        } else {
            this.setState({
                error: 'Please select a file below 500KB',
                file: [],
                fileView: ''
            })
        }


    }

    __getFile = (file_id) => {
        let d = {
            file_id: file_id
        }
        PostData(`/getfilesingle`, d)
            .then((resp) => {
                if (resp.hasOwnProperty('mime') && resp.hasOwnProperty('data')) {
                    let file = '';
                    if (resp.mime === 'image/jpeg' || resp.mime === 'image/png' || resp.mime === 'image/jpg') {
                        file = `data:${resp.mime};base64,${resp.data}`;
                    } else if (resp.mime === 'application/pdf') {
                        file = 'https://png.pngtree.com/svg/20170509/5023fbf29c.png';
                    }
                    this.setState({
                        fileView: file
                    })
                }
            })
    }

    componentDidMount() {
        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            this.setState({
                result: data,
                isUploaded: true,
                isUploading: true
            }, () => this.__getFile(this.state.result[0].file_id))
        }

        // CEE ROLL NO CHECK

        let cee_roll_no = this.props.cee_roll_no
        console.log(cee_roll_no)

        if (cee_roll_no !== '' || cee_roll_no !== undefined) {
            let d = {
                cee_roll_no: cee_roll_no,
                file: this.props.file_type
            }
            PostData(`/getfilesbyrollno`, d)
                .then((resp) => {
                    if (resp !== null) {
                        this.setState({
                            fileView: resp,
                            isUploaded: true,
                            fromCee: true
                        })
                    }

                })
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                this.setState({
                    result: data,
                    isUploaded: true,
                    isUploading: true
                }, () => this.__getFile(this.state.result[0].file_id))
            }
        }


    }

    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>

                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    {this.props.title}

                    &nbsp;
                    <span>
                        <Tooltip title={toolText}>
                            <HelpOutline
                                style={{
                                    fontSize: 15,
                                    color: 'grey'
                                }}
                            />
                        </Tooltip>
                    </span>

                </div>




                {this.state.error !== '' ? (
                    <div>
                        <Typography
                            variant="body2"
                            style={{ color: 'red' }}
                        >
                            {this.state.error}
                        </Typography>
                    </div>
                ) : null}
                <div className={classes.container}>

                    {!this.state.isUploading ?
                        (<div className={classes.uploaderDiv}>
                            <input
                                className="form-control"
                                type="file"
                                accept={'image/jpeg,image/x-png'}
                                name="file_upload"
                                onChange={this.onChange}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.uploadFile}
                            >
                                Upload
                        </Button>
                        </div>) :
                        <div className={classes.uploaderDiv}>
                            {!this.state.isUploaded ? <Typography
                                variant="caption"
                            >
                                <CircularProgress size={24} />Uploading your file . . . .Please wait
                            </Typography> :
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={this.deleteFile}
                                >
                                    Remove
                        </Button>
                            }
                        </div>
                    }

                    <div className={classes.photoHolder}
                        style={{ height: this.props.type === 'ADMISSION_SIGNATURE' ? 100 : 300 }}>
                        {this.state.isUploaded ? <img
                            src={this.state.fileView}
                            className={classes.uploadedFile}
                        /> : null}
                    </div>
                </div>

            </div>
        )
    }
}

export default withStyles(styles)(FileUpload)


const PdfView = (props) => {

    if (props.show) {

        let i = '';
        if (Array.isArray(props.name) && props.name.length > 0) {
            if (props.name[0].hasOwnProperty('file_id')) {
                i = props.name[0].file_id.split('_').join(' ');
            }

        }

        return (
            <div style={{
                padding: '20px',
                display: 'flex',
                alignItems: 'center'
            }}>
                <PictureAsPdf
                    style={{ fontSize: 40 }}
                />
                <Typography
                    style={{ paddingLeft: '10px' }}
                >
                    {i}
                </Typography>
            </div>
        )
    } else {
        return null;
    }

}