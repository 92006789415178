import React, { Component } from 'react';
import './css/welcome.css';
import { withStyles, Typography, Button, Fade, TextField } from '@material-ui/core';
import { blue } from '@material-ui/core/colors'
import { PostData } from '../../api/service';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    },
    buttonSearch: {
        marginTop: theme.spacing.unit * 2,
        padding: theme.spacing.unit * 2
    }
})


class Reprint extends Component {
    state = {
        email: '',
        isSubmitting: false,
        message: ''
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        let d = {
            email: this.state.email
        }

        PostData(`/getstudentidbyemail`, d)
        .then((resp) => {
            console.log(resp)
            if(resp!== false){
                this.props.history.replace('/acknoledgement', resp)
            }else{
                this.setState({
                    message: 'Your email id is not registered with us. Please register to continue'
                })
            }
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <Fade in={true}>
                <div className={classes.root}>

                    <img
                        alt="aec-logo"
                        src={require('./../../assets/logo.png')}
                        style={{ size: '40%' }}
                    />

                    <br />

                    <Typography
                        variant="h4"
                        style={{ fontWeight: 300 }}
                    >
                        Reprint Acknowledgement
          </Typography>

                   

                    <br />

                    <div className="container mt-5">

                        <Typography
                            variant="subtitle2"
                            style={{
                                color: 'red',
                                fontWeight: '300',
                                textAlign: 'center'
                            }}
                        >
                            {this.state.message}
                        </Typography>

                        <div style={{display: 'flex', justifyContent: 'center'}} >

                            <form onSubmit={this.onSubmit}>

                                <TextField
                                    label="Please enter your email id"
                                    type="email"
                                    name="email"
                                    value={this.state.form_id}
                                    onChange={this.onChange}
                                    margin="normal"
                                    variant="outlined"
                                    required
                                    style={{width: '300px'}}
                                />

                                <Button
                                    type="submit"
                                    className={classes.buttonSearch}
                                    color="primary"
                                >
                                    Reprint
                                </Button>


                            </form>
                        </div>

                    </div>

                    <div className="container mt-5">

                        <div align="center">

                            <Typography
                                variant="caption"
                            >
                                <span style={{ color: blue[700], cursor: 'pointer' }} onClick={() => this.props.history.replace('/')}>Click here</span> to go to Homepage
              </Typography>

                        </div>

                    </div>

                </div>
            </Fade>
        )
    }
}

export default withStyles(styles)(Reprint)