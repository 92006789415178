import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles';
import { Table, TableBody, TableHead, TableRow, TableCell, Button, Typography, IconButton } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({

})

class LastSchool extends Component {
    state = {
        examination: '',
        board: '',
        school: '',
        roll_no: '',
        year_of_passing: '',
        percentage: '',
        division: '',
        errors: [],
        data: []
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = () => {
        let { examination, board, school, roll_no, year_of_passing, percentage, division, errors } = this.state;
        let isValid = true;

        if (examination === '') {
            isValid = false;
            errors['examination'] = 'Required'
        } else {
            errors['examination'] = '';
        }

        if (board === '') {
            isValid = false;
            errors['board'] = 'Required'
        } else {
            errors['board'] = '';
        }

        if (school === '') {
            isValid = false;
            errors['school'] = 'Required'
        } else {
            errors['school'] = '';
        }

        if (roll_no === '') {
            isValid = false;
            errors['roll_no'] = 'Required'
        } else {
            errors['roll_no'] = '';
        }

        if (year_of_passing === '') {
            isValid = false;
            errors['year_of_passing'] = 'Required'
        } else {
            errors['year_of_passing'] = '';
        }

        if (percentage === '') {
            isValid = false;
            errors['percentage'] = 'Required'
        } else {
            errors['percentage'] = '';
        }

        if (division === '') {
            isValid = false;
            errors['division'] = 'Required'
        } else {
            errors['division'] = '';
        }

        this.setState({
            errors
        })
        return isValid;

    }

    onAdd = () => {
        
        if (this.handleSubmit()) {
            
            let { examination, board, school, roll_no, year_of_passing, percentage, division, data } = this.state;
            let d = {
                examination, board, school, roll_no, year_of_passing, percentage, division
            }
            

            let r = data.filter(el => el.examination === this.state.examination);
            
            if (Array.isArray(r) && r.length === 0) {
                data.push(d);
                this.setState({
                    data,
                    examination: '',
                    board: '',
                    school: '',
                    roll_no: '',
                    year_of_passing: '',
                    percentage: '',
                    division: '',
                }, () => {
                        this.props.onChangeData(this.state.data)
                })
            }
        }
    }

    deleteItem =  (index) => {
        let data = this.state.data;
        let d = data.splice(index,1);
        this.setState({
            d: data
        }, () => {
                this.props.onChangeData(this.state.data)
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div>

                <Typography
                    variant="h6"
                    gutterButton
                >
                    Previous Examination Details
                </Typography>

                <Typography
                    variant="caption"
                    style={{ color: 'red' }}
                >
                    Donot forget to click Add button after inserting the details. Please insert both class 10th and 12th data accordingly
                            </Typography>

                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Examination
                            </TableCell>
                            <TableCell>
                                Board / University
                            </TableCell>
                            <TableCell>
                                School / College
                            </TableCell>
                            <TableCell>
                                Roll No
                            </TableCell>
                            <TableCell>
                                Year of Passing
                            </TableCell>
                            <TableCell>
                                Percentage
                            </TableCell>
                            <TableCell>
                                Division/Grade
                            </TableCell>
                            <TableCell>
                                &nbsp;
                            </TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        <TableRow style={{ verticalAlign: 'top' }}>
                            <TableCell padding="none">
                                <select
                                    className="form-control"
                                    placeholder="Select Program"
                                    onChange={this.onChange}
                                    name="examination"
                                    
                                    value={this.state.examination}>
                                    <option value="">Examination</option>

                                    <option value="10th">10th</option>
                                    <option value="12th">12th</option>
                                    <option value="Diploma">Diploma</option>
                                    <option value="Degree">Degree</option>
                                    <option value="Certificate">Certificate</option>
                                    <option value="Other">Other</option>

                                </select>
                                <Typography
                                    variant="caption"
                                    style={{ color: 'red' }}
                                >
                                    {this.state.errors['examination']}
                                </Typography>

                            </TableCell>
                            <TableCell padding="none">
                                <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={`Board/University`}
                                    onChange={this.onChange}
                                    name={`board`}
                                    
                                    value={this.state.board}
                                />

                                <Typography
                                    variant="caption"
                                    style={{ color: 'red' }}
                                >
                                    {this.state.errors['board']}
                                </Typography>
                            </TableCell>
                            <TableCell padding="none">
                                <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={`School / College`}
                                    onChange={this.onChange}
                                    name={`school`}
                                    
                                    value={this.state.school}
                                />
                                <Typography
                                    variant="caption"
                                    style={{ color: 'red' }}
                                >
                                    {this.state.errors['school']}
                                </Typography>
                            </TableCell>
                            <TableCell padding="none">
                                <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={`Roll No`}
                                    onChange={this.onChange}
                                    name={`roll_no`}
                                    
                                    value={this.state.roll_no}
                                />
                                <Typography
                                    variant="caption"
                                    style={{ color: 'red' }}
                                >
                                    {this.state.errors['roll_no']}
                                </Typography>
                            </TableCell>
                            <TableCell padding="none">
                                <input
                                    type="number"
                                    minLength="4"
                                    maxLength="4"
                                    className="form-control input-sm"
                                    placeholder={`Year`}
                                    onChange={this.onChange}
                                    name={`year_of_passing`}
                                    
                                    value={this.state.year_of_passing}
                                />
                                <Typography
                                    variant="caption"
                                    style={{ color: 'red' }}
                                >
                                    {this.state.errors['year_of_passing']}
                                </Typography>
                            </TableCell>
                            <TableCell padding="none">
                                <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={`Percentage`}
                                    onChange={this.onChange}
                                    name={`percentage`}
                                    
                                    value={this.state.percentage}
                                />
                                <Typography
                                    variant="caption"
                                    style={{ color: 'red' }}
                                >
                                    {this.state.errors['percentage']}
                                </Typography>
                            </TableCell>
                            <TableCell padding="none">
                                <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={`Division/Grade`}
                                    onChange={this.onChange}
                                    name={`division`}
                                    
                                    value={this.state.division}
                                />
                                <Typography
                                    variant="caption"
                                    style={{ color: 'red' }}
                                >
                                    {this.state.errors['division']}
                                </Typography>
                            </TableCell>
                            <TableCell padding="none">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.onAdd}
                                >
                                    Add
                                </Button>
                            </TableCell>
                        </TableRow>


                    </TableBody>
                    {
                        Array.isArray(this.state.data) && this.state.data.length > 0 ? (
                            <TableBody>

                                {this.state.data.map((el, index) =>

                                    <TableRow key={index}>
                                        <TableCell >
                                            <Typography
                                                variant="body1"
                                            >
                                                {el.examination}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography
                                                variant="body1"
                                            >
                                                {el.board}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography
                                                variant="body1"
                                            >
                                                {el.school}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography
                                                variant="body1"
                                            >
                                                {el.roll_no}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography
                                                variant="body1"
                                            >
                                                {el.year_of_passing}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography
                                                variant="body1"
                                            >
                                                {el.percentage}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography
                                                variant="body1"
                                            >
                                                {el.division}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" >
                                            <IconButton
                                                onClick={this.deleteItem.bind(this,index)}
                                            >
                                                <Delete />
                                            </IconButton>
                                            
                                        </TableCell>
                                    </TableRow>

                                )}

                            </TableBody>
                        ) : null
                    }
                </Table>
            </div>
        )
    }
}

export default withStyles(styles)(LastSchool)
