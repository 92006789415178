import React, { Component } from "react";
import "./css/welcome.css";
import {
  withStyles,
  Typography,
  Button,
  Fade,
  TextField,
  Container,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { PostData, GetData } from "../../api/service";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  buttonSearch: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
});

class Welcome extends Component {
  state = {
    data: [],
    roll_no: "",
    isLoaded: false,
    notice: [],
    noticeLoaded: false,
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    GetData(`/admissionnotification`).then((resp) => {
      //console.log(resp)
      this.setState({
        notice: resp,
        noticeLoaded: true,
      });
    });
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.roll_no !== "") {
      let d = {
        roll_no: this.state.roll_no,
      };
      PostData(`/getceedata`, d).then((resp) => {
        if (resp === false) {
          confirmAlert({
            title: "Sorry !!",
            message: "No Matching Data found",
            buttons: [
              {
                label: "Try Traditional Way",
                onClick: () => this.props.history.push("/signup"),
              },
            ],
          });
        } else {
          this.props.history.push("/signup", resp);
        }
      });
    }
  };
  render() {
    let { classes } = this.props;
    return (
      <Fade in={true}>
        <div className={classes.root}>
          <img
            alt="aec-logo"
            src={require("./../../assets/logo.png")}
            style={{ size: "40%" }}
          />

          <br />

          <Typography variant="h6" style={{ fontWeight: 300 }}>
            Welcome to
          </Typography>

          <Typography variant="h5">Assam Engineering College</Typography>

          <br />

          <Container>
            {this.state.noticeLoaded ? (
              <div>
                {this.state.notice.map((el, index) => (
                  <div className="alert alert-danger mb-2" key={index}>
                    <Typography variant="subtitle2">
                      {el.title} :{" "}
                      <Typography style={{ display: "inline" }} variant="body2">
                        {el.description}
                      </Typography>
                    </Typography>
                  </div>
                ))}
              </div>
            ) : null}
          </Container>

          <div className="container mt-2">
            {/* <div style={{ display: 'flex', justifyContent: 'center' }} >

              <form onSubmit={this.onSubmit}>

                <TextField
                  label="Enter CEE Roll Number"
                  type="number"
                  min="0"
                  name="roll_no"
                  value={this.state.roll_no}
                  onChange={this.onChange}
                  margin="normal"
                  variant="outlined"
                  required
                  style={{ width: '200px' }}
                />

                <Button
                  type="submit"
                  className={classes.buttonSearch}
                  color="primary"

                >
                  Submit
                                </Button>


              </form>
            </div> */} 

            <br />

            <div style={{textAlign: "center"}}>
              <Button onClick={() => this.props.history.push("/signup")} variant="contained" color="primary">
                Go to Registration
              </Button>
            </div>

            <br />

            <div align="center">
              <Typography variant="caption">
                <span
                  style={{ color: blue[700], cursor: "pointer" }}
                  onClick={() => this.props.history.push("/signup")}
                >
                  Click here
                </span>{" "}
                to fill up the form in old traditional way
              </Typography>
            </div>
          </div>

          <div className="container mt-5">
            <div align="center">
              <Typography variant="caption">
                <span
                  style={{ color: blue[700], cursor: "pointer" }}
                  onClick={() => this.props.history.replace("/reprint")}
                >
                  Click here
                </span>{" "}
                to reprint the acknowledgement
              </Typography>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default withStyles(styles)(Welcome);
