import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import DrawerMain from './DrawerMain';
import AuthService from './../auth_components/js/AuthService';
import { Menu, MenuItem, Badge, Avatar } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Settings from '@material-ui/icons/Settings';
import FilterList from '@material-ui/icons/FilterList';
import MoreIcon from '@material-ui/icons/MoreVert';
import Refresh from '@material-ui/icons/Refresh';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { GetData } from './../api/service'
import { blue } from '@material-ui/core/colors';

const drawerWidth = 230;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        height: 50,
        backgroundColor: 'white',
        
        boxShadow: '1px 2px 3px rgba(0,0,0,0.1)',
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },

    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'center'
        },
        flexBasis: '100%',
        textAlign: 'right'
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    grow: {
        flexGrow: 1,
    },
    toolbarClass: {
        
        color: blue[900]
    },
    profileButton: {
        borderRadius: '0',

    },
    ava: {
        width: 30,
        height: 30
    },
    notificationsIcon: {
        width: 30,
        height: 30
    }
});

class ResponsiveDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.onLogout = this.onLogout.bind(this);

    }

    state = {
        mobileOpen: false,
        anchorEl: null,
        mobileMoreAnchorEl: null,
        count_noti: 0,

    };



    forceReload = () => {
        window.location.reload();
    }


    onLogout() {
        this.Auth.logout();
        this.props.history.replace('/login');
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    render() {
        const { classes, theme } = this.props;
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem><Link to="/settings">Change Password</Link></MenuItem>
                <MenuItem onClick={this.onLogout}>Logout</MenuItem>
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                
                <Link to="/settings"><MenuItem>
                    <IconButton color="inherit">
                        <Settings />
                    </IconButton>
                    <p>Change Password</p>
                </MenuItem></Link>

                <MenuItem onClick={this.onLogout}>
                    <IconButton color="inherit">
                        <FilterList />
                    </IconButton>
                    <p>Logout</p>
                </MenuItem>
            </Menu>
        );

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar variant="regular" className={classes.toolbarClass}>
                        
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>

                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>

                            <IconButton color="inherit" onClick={this.forceReload}>
                                <Refresh />
                            </IconButton> 
                            <IconButton
                                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleProfileMenuOpen}
                                color="inherit"
                                className={classes.profileButton}
                            >
                                <Typography
                                    variant="caption"
                                >
                                    {this.props.username}
                                </Typography> &nbsp;
                                <Avatar className={classes.ava}>
                                    {this.props.username[0]}
                                </Avatar>
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton color="inherit" onClick={this.forceReload}>
                                <Refresh />
                            </IconButton> 
                            <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMenu}
                {renderMobileMenu}
                <nav className={classes.drawer}>
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={this.props.container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <DrawerMain username={this.props.username} />
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            <DrawerMain username={this.props.username} />
                        </Drawer>
                    </Hidden>
                </nav>
            </div>
        );
    }
}

ResponsiveDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);