import React, { Component } from 'react'
import { withStyles, Typography, Grid, Checkbox } from '@material-ui/core';

const styles = theme => ({

})

const rows = 3;

class LanguagesKnown extends Component {
    state = {
        language_name: ['','',''],
        read: [false,false,false],
        write: [false,false,false],
        speak: [false,false,false],
        languages: []
    }
    onChange = (index, name, e) => {
        let { language_name, read, write, speak } = this.state;

        if (name === 'language_name') {
            language_name[index] = e.target.value;
        }

        if (name === 'read') {
            read[index] = e.target.checked;
        }

        if (name === 'write') {
            write[index] = e.target.checked;
        }

        if (name === 'speak') {
            speak[index] = e.target.checked;
        }

        this.setState({
            language_name,
            read,
            write,
            speak
        }, () => {
            let { language_name, read, write, speak } = this.state;
            let languages = [];
            for (let i = 0; i < rows; i++) {
                let d = {
                    language_name: language_name[i],
                    read: read[i] || false,
                    write: write[i] || false,
                    speak: speak[i] || false
                }
                languages.push(d)
            }
            this.props.onChangeData(languages)
        })
    }


    render() {
        let { classes } = this.props;
        let rr = [];
        for (let i = 0; i < rows; i++) {
            rr.push(
                <Grid key={i} container spacing={2}>
                    <Grid item xs={4} sm={4} lg={6} md={6}>
                        <Typography
                            variant="subtitle2"
                        >
                            Name of the Language 
                        </Typography>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={`Name of the language`}
                            onChange={this.onChange.bind(this, i, 'language_name')}
                            name={`language_name`}
                            value={this.state.language_name[i]}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} lg={2} md={2} align="center">
                        <Typography
                            variant="subtitle2"
                        >
                            Read
                        </Typography>

                        <Checkbox
                            checked={this.state.read[i]}
                            name="read"
                            onChange={this.onChange.bind(this, i, 'read')}
                            value={this.state.read[i]}
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} lg={2} md={2} align="center">
                        <Typography
                            variant="subtitle2"
                        >
                            Write
                        </Typography>

                        <Checkbox
                            checked={this.state.write[i]}
                            name="write"
                            onChange={this.onChange.bind(this, i, 'write')}
                            value={this.state.write[i]}
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} lg={2} md={2} align="center">
                        <Typography
                            variant="subtitle2"
                        >
                            Speak
                        </Typography>

                        <Checkbox
                            checked={this.state.speak[i]}
                            name="speak"
                            onChange={this.onChange.bind(this, i, 'speak')}
                            value={this.state.speak[i]}
                            color="primary"
                        />
                    </Grid>
                </Grid>
            )
        }
        return (
            <div>
                <Typography
                    variant="h6"
                    gutterBottom
                >
                    Languages Known
                </Typography>

                {rr}

            </div>
        )
    }
}

export default withStyles(styles)(LanguagesKnown)