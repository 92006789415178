import React, { Component } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import OtpInput from 'react-otp-input';
import { PostData } from '../../api/service';

const styles = theme => ({

})

class FinalDialog extends Component {
    state = {
        open: true,
        step: 0,
        otp: '',
        otp_verified: 0,
        is_submitting: false,
        message: ''
    }
    componentDidMount() {

    }

    digits_count(n) {
        var count = 0;
        if (n >= 1)++count;

        while (n / 10 >= 1) {
            n /= 10;
            ++count;
        }

        return count;
    }

    onOtpChange = (otp) => {
        if(this.digits_count(otp) === 6){
            //send the OTP now

            this.setState({
                otp: otp,
                is_submitting: true,
                message: 'Submitting . . . . Please Wait'
            })


            let d = {
                otp: otp,
                phone: this.props.phone
            }

            PostData(`/checkregistrationotp`, d)
            .then((resp) => {
                if(resp){
                    
                    this.setState({
                        message: 'Phone verification complete'
                    })
                    setTimeout(this.props.finalSubmit(1), 1000)
                }else{
                    this.setState({
                        message: 'you have entered wrong OTP. Please try again'
                    })
                }
            })

        }
    }

    handleSkip = () => {
        this.props.finalSubmit(0);
        this.setState({
            open: false
        })
    }

    verifyClick = () => {
        let d = {
            phone: this.props.phone
        }

        PostData(`/sendregistrationotp`, d)
        .then((resp) => {
            
            this.setState({
                step: 1
            })
        })
    }



    render() {
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    
                >
                    <DialogTitle>{"Phone verification"}</DialogTitle>
                    {this.state.step === 0 ? (<DialogContent>
                        <DialogContentText>
                            After clicking the verify button an OTP will be sent to your phone number : {this.props.phone}
                        </DialogContentText>
                        <DialogContentText>
                            You can skip this step and verify later.
                        </DialogContentText>
                    </DialogContent>) : null}

                    {this.state.step === 1 ? (<DialogContent style={{textAlign: 'center'}}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                            <OtpInput
                                onChange={this.onOtpChange}
                                numInputs={6}
                                separator={<span>&nbsp;-&nbsp;</span>}
                                inputStyle={{ width: '50px', padding: '10px' }}
                            />

                    </div>

                    <br />
                        

                        {!this.state.is_submitting ? (<Typography
                            variant="caption"
                            
                        >   
                            you will receive OTP within 2-3 mins,
                            if you don't receive OTP, <Button variant="text" size="small" onClick={this.verifyClick}>Click here</Button> to resend
                        </Typography>) : (
                            <Typography
                                variant="caption"
                            >
                            {this.state.message}
                            </Typography>
                        )}
                    </DialogContent>) : null}

                    <DialogActions>
                        <Button onClick={this.handleSkip}>
                            Skip this time
                        </Button>
                        {this.state.step === 0 ? (<Button onClick={this.verifyClick} color="primary" >
                            Verify
                        </Button>) : null}
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default withStyles(styles)(FinalDialog)
