import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';
import Reprint from '../Components/Homepage/Reprint';


class HomePage extends Component {

  render() {
    return (
      <div>
        
        

          <Switch>
            <Route exact path="/" render={(props) =>
              <Welcome
                {...props}
                history={this.props.history}
              />
            } />

          <Route exact path="/reprint" render={(props) =>
            <Reprint
              {...props}
              history={this.props.history}
            />
          } />

          </Switch>



        
      </div>
    )
  }
}

export default HomePage;